import React, { useState, useEffect, useRef } from "react";
import { Image, Button, Modal } from "react-bootstrap";
import { RxTriangleLeft, RxTriangleRight } from "react-icons/rx";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { ImSpinner5 } from "react-icons/im";
import WatermarkImage from "../watermarkImage";
import { saveAs } from 'file-saver';

export default function ImageGallery(props) {
  const { t } = useTranslation();
  const countItems = props.listImagesGallery && props.listImagesGallery.length;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(0);
  const containerRef = useRef();

  const loadMoreImages = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const bottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 1;
    if (bottom) {
      loadMoreImages();
    }
  };

  const openModal = (index) => {
    setCurrent(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleBodyClick = (e) => {
    if (modalIsOpen) {
      const modalContent = document.querySelector(".modal-open");
      const modalContentBody = document.querySelector(".fade.modal.show");
      if (modalContent.contains(e.target)) {
        modalContentBody.contains(e.target) === true && closeModal();
      }
    }
  };

  const nextSlide = () => {
    setLoading(true);
    setTimeout(() => {
      setCurrent((current + 1) % countItems);
      setLoading(false);
    }, 500);
  };

  const prevSlide = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setCurrent((current - 1 + countItems) % countItems);
    }, 500);
  };


  const fixImageUrl = (url) => {
    const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    
    if (isLocal && url.startsWith('https://')) {
      return url.replace('https://', 'http://'); // Use http for local
    } else if (!isLocal && url.startsWith('http://')) {
      return url.replace('http://', 'https://'); // Use https for production
    }
    return url;
  };
  
  const downloadImageFile = async (props) => {
    let fileUrl = fixImageUrl(props.image);
    console.log('Downloading from:', fileUrl);
  
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
  
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
      }
  
      const blob = await response.blob();
      saveAs(blob, fileName);
      console.log('File downloaded successfully:', fileName);
    } catch (error) {
      console.error('Error downloading file:', error.message);
    }
  };
  

  useEffect(() => {
    setCurrentPage(0); // Reset current page when modal is closed

    document.addEventListener("click", handleBodyClick);
    return () => {
      document.removeEventListener("click", handleBodyClick);
    };
  }, [modalIsOpen]);

  return (
    <div ref={containerRef} className="pre-scrollables" onScroll={handleScroll}>
      <ul className="row d-lg-flex list-unstyled image-block px-lg-0 mx-lg-0">
        {props.listImagesGallery &&
          props.listImagesGallery
            .slice(0, (currentPage + 1) * itemsPerPage)
            .map((list, index) => (
              <li
                key={index}
                className="col-lg-2 col-md-3 col-sm-6 image-block full-width"
              >
                <div className="list-images-gallery">
                  <WatermarkImage src={list.image} alt={""}  onClick={() => openModal(index)} key={index}/>
        
                </div>
              </li>
            ))}
      </ul>

      {modalIsOpen ? (
        <div className="close-modal">
          <IoCloseCircleSharp color="#fff" size={50} onClick={closeModal} />
        </div>
      ) : null}
      <div className="prev-icon">
        {modalIsOpen && countItems > 1 ? (
          <RxTriangleLeft
            size={60}
            color={current === 0 ? "#848484" : "#fff"}
            className="left-arrow"
            onClick={current === 0 ? null : prevSlide} />
        ) : null}
      </div>
      <Modal
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
        show={modalIsOpen}
        backdrop="static"
        onHide={closeModal}
        dialogClassName="modal-90w text-center"
        contentClassName="transparentBgClass"
        centered
        scrollable={true}
      >
        <div className="content-dailog-modal" onClick={closeModal}>
          <Modal.Body>
            {countItems > 0
              ? props.listImagesGallery &&
                props.listImagesGallery.map((resItem, index) => (
                  <div
                    className={index === current ? "slide active" : "slide"}
                    key={index}
                  >
                    {index === current && (
                      <>
                        {loading ? (
                          <div className="bg-spiner"><ImSpinner5 className="loaderIcon" size={40} color="#000" /></div>
                        ) : (
                          <WatermarkImage src={resItem.image} alt={""}  o key={index} className="img-responsive w-100 rounded"/>
                         
                        )}

                      <h4
                        onClick={() => downloadImageFile(resItem)}
                        className="btn-donwlaod-image"
                      >
                        {t("download")}
                      </h4>
                    </>
                  )}
                </div>
              ))
              : null}
          </Modal.Body>
        </div>
      </Modal>
      <div className="next-icon">
        {modalIsOpen && countItems > 1  ? (
          <RxTriangleRight
            size={60}
            color={current + 1 === countItems ? "#848484" : "#fff"}
            className="right-arrow"
            onClick={current + 1 === countItems ? null : nextSlide}
          />
        ) : null}
      </div>
    </div>
  );
}
